import { richText, stripRichTextWrapperTag } from '../../utils/html';
import { adapter as imageAdapter } from '@/components/cloud-image/utils';

export default (
  { primary, items },
  pageData,
  { $i18n, $enhancedLinkSerializer }
) => {
  return {
    title: stripRichTextWrapperTag(primary.picto_carousel_title),
    cards: items.map(
      ({
        picto_card_icon,
        picto_card_subtitle,
        picto_card_title,
        picto_card_text,
      }) => ({
        icon: imageAdapter(picto_card_icon),
        subtitle: richText(picto_card_subtitle),
        title: richText(picto_card_title),
        text: richText(picto_card_text),
      })
    ),
    primaryButton: {
      label:
        primary.picto_carousel_primary_link_label || $i18n.t('cta.souscrire'),
      link: $enhancedLinkSerializer(primary.picto_carousel_primary_link),
    },
    secondaryButton: {
      label:
        primary.picto_carousel_secondary_link_label ||
        $i18n.t('cta.demander-devis'),
      link: $enhancedLinkSerializer(primary.picto_carousel_secondary_link),
    },
  };
};
